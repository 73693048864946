import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import TagRoute from '../templates/tags'
import TagList from './TagList'

class BlogSideBar extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="column is-4 blog-sidebar box">
          <h2 className="title is-size-3">The blog</h2>
          <p>When not developing stuff, I love to share my thoughts and experiences related to software and games development. I hope you'll find it useful!</p>
          <h2 className="title is-size-3">Latest articles</h2>
          <ul className="blog-sidebar-latest">
            {posts &&
            posts.map(({ node: post }) => (
                <li className="post-meta" key={post.id}>
                    <p>
                        <Link className="has-text-primary is-size-6" to={post.fields.slug} >
                            {post.frontmatter.title}
                        </Link>
                    </p>
                </li>
            ))}
        </ul>
        <div>
      <TagList />
    </div>
      </div>
    )
  }
}

BlogSideBar.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
    <StaticQuery
      query={graphql`
      query BlogSideBarQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            limit: 5
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogSideBar data={data} count={count} />}
    />
  )
  