import React from 'react'
import { Link, graphql } from 'gatsby'
import BlogRoll from '../components/BlogRoll'
import BlogSideBar from '../components/BlogSideBar'
import Layout from '../components/Layout'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/home-jumbotron.jpg')`,
            backgroundPosition: `center center`,
            backgroundAttachment: `fixed`
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #076950, -0.5rem 0 0 #076950',
              backgroundColor: '#076950',
              color: 'white',
              padding: '1rem',
            }}
          >
            All blog posts
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns">
                <BlogSideBar />
                <div>
                  <BlogRoll count={-1} posts={data.allMarkdownRemark} />
                  <ul
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      alignItems: 'center',
                      listStyle: 'none',
                      padding: 0,
                    }}
                  >
                    {!isFirst && (
                      <Link to={prevPage} rel="prev" style={{
                        fontWeight: 'bold'
                      }}>
                        Previous Page
                      </Link>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                      <li
                        key={`pagination-number${i + 1}`}
                        style={{
                          margin: 0,
                          marginLeft: 16,
                          fontWeight: 'bold',
                        }}
                      >
                        <Link
                          to={`/blog/${i === 0 ? '' : i + 1}`}
                          style={{
                            textDecoration: i + 1 === currentPage ? 'underline' : 'none',
                            fontWeight: 'bold'
                          }}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    {!isLast && (
                      <Link to={`/blog/${nextPage}`} rel="next" style={{
                        fontWeight: 'bold',
                        marginLeft: 16,
                      }}>
                        Next Page
                      </Link>
                    )}
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`