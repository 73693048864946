import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Link, graphql, StaticQuery } from 'gatsby'

class TagList extends React.Component {
    render() {
        const { data } = this.props
        const { group: tags } = data.allMarkdownRemark
  return (
    <div>
        <h1 className="title is-size-3 is-bold-light">Tags</h1>
        <ul className="blog-sidebar-taglist">
            {tags.map((tag) => (
            <li key={tag.fieldValue}>
                <Link className="has-text-primary" to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
                </Link>
            </li>
            ))}
        </ul>
    </div>
    )
    }
}
TagList.propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
  }

export default () => (
    <StaticQuery
      query={graphql`
        query TagListQuery {
            allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
            }
        }
        `}
        render={(data) => <TagList data={data} />}
      />
    )
